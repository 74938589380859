import { Cards } from '@/components/Bloks/Cards';
import { Contact } from '@/components/Bloks/Contact';
import { CtaCallout } from '@/components/Bloks/CtaCallout';
import { FAQs } from '@/components/Bloks/FAQs';
import { FeatureTabs } from '@/components/Bloks/FeatureTabs';
import { FeatureTiles } from '@/components/Bloks/FeatureTIles';
import { FormWithHeading } from '@/components/Bloks/FormWithHeading';
import { Hero } from '@/components/Bloks/Hero/Hero';
import { IBuildContent } from '@/components/Bloks/IBuild/IBuildContent';
import { IBuildImage } from '@/components/Bloks/IBuild/IBuildImage';
import { IBuildList } from '@/components/Bloks/IBuild/IBuildList';
import { IBuildStatistics } from '@/components/Bloks/IBuild/IBuildStatistics';
import { IBuildTestimonial } from '@/components/Bloks/IBuild/IBuildTestimonial';
import { IBuildTabs } from '@/components/Bloks/IBuildTabs';
import { ImageCarousel } from '@/components/Bloks/ImageCarousel';
import { ImageLeftRight } from '@/components/Bloks/ImageLeftRight';
import { Insight } from '@/components/Bloks/Insight';
import { InsightsFilter } from '@/components/Bloks/Insights/InsightsFilter';
import { InsightsOverview } from '@/components/Bloks/InsightsOverview';
import { Locations } from '@/components/Bloks/Locations';
import { LogoCarousel } from '@/components/Bloks/LogoCarousel';
import { Page } from '@/components/Bloks/Page';
import { PricingPanels } from '@/components/Bloks/PricingPanels';
import { Testimonials } from '@/components/Bloks/Testimonials';
import { ThirdPartyScript } from '@/components/Bloks/ThirdPartyScript';
import { WebWidget } from '@/components/Bloks/WebWidget';
import { WorkableWidget } from '@/components/Bloks/WorkableWidget';
import { WYSIWYG } from '@/components/Bloks/WYSIWYG';
import { WysiwygBlockquote } from '@/components/Bloks/Wysiwyg/WysiwygBlockquote';
import { WysiwygContent } from '@/components/Bloks/Wysiwyg/WysiwygContent';
import { WysiwygImage } from '@/components/Bloks/Wysiwyg/WysiwygImage';
import { WysiwygVideo } from '@/components/Bloks/Wysiwyg/WysiwygVideo';
import { WysiwygVimeo } from '@/components/Bloks/Wysiwyg/WysiwygVimeo';
import { WysiwygYoutube } from '@/components/Bloks/Wysiwyg/WysiwygYoutube';
import { FooterClientProvider } from '@/components/Providers/FooterProvider';
import { HeaderClientProvider } from '@/components/Providers/HeaderProvider';
import { InsightsProvider } from '@/components/Providers/InsightsProvider';
import { SettingsClientProvider } from '@/components/Providers/SettingsProvider';
import { StoryblokClientProvider } from '@/components/Providers/StoryblokProvider';
import { FormCalendly } from '@/components/Shared/Form/FormCalendly';
import { FormHubspot } from '@/components/Shared/Form/FormHubspot';
import { SbForm } from '@/components/Shared/Form/SbForm';
import { SbFormCheckbox } from '@/components/Shared/Form/SbFormCheckbox';
import { SbFormGroup } from '@/components/Shared/Form/SbFormGroup';
import { SbFormInput } from '@/components/Shared/Form/SbFormInput';
import { SbFormRow } from '@/components/Shared/Form/SbFormRow';
import { SbFormSelect } from '@/components/Shared/Form/SbFormSelect';
import { SbFormTextarea } from '@/components/Shared/Form/SbFormTextarea';
import { FooterContact } from '@/components/Shared/Layout/Footer/FooterContact';
import { FooterItem } from '@/components/Shared/Layout/Footer/FooterItem';
import { IconTile } from '@/components/Shared/Tiles/IconTile';
import { NumberTile } from '@/components/Shared/Tiles/NumberTile';
// import { pageview } from '@/utils/google/googleTagManager';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import { AppProps } from 'next/app';
import Head from 'next/head';

import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import { SelfSubscribePage } from '@/components/Bloks/SelfSubscribePage';
import { SelfSubscribeCarousel } from '@/components/SelfSubscribe/Shared/SelfSubscribeCarousel';
import { SelfSubscribeImage } from '@/components/SelfSubscribe/Shared/SelfSubscribeImage';
import { SelfSubscribeVideo } from '@/components/SelfSubscribe/Shared/SelfSubscribeVideo';
import './../../styles/globals.scss';

import 'isomorphic-fetch';
import { SelfSubscribeImageGraphic } from '@/components/SelfSubscribe/Shared/SelfSubscribeImageGraphic';
import { QueryClient, QueryClientProvider } from 'react-query';

storyblokInit({
	accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN,
	apiOptions: {
		cache: { type: 'memory' },
	},
	use: [apiPlugin],
	bridge:
		typeof window !== 'undefined'
			? window.location !== window.parent.location
			: false,
	components: {
		cards: Cards,
		contact: Contact,
		cta_callout: CtaCallout,
		faqs: FAQs,
		feature_card: IconTile,
		feature_card_number: NumberTile,
		feature_tabs: FeatureTabs,
		feature_tiles: FeatureTiles,
		footer_contact: FooterContact,
		footer_item: FooterItem,
		form_calendly: FormCalendly,
		form_checkbox: SbFormCheckbox,
		form_group: SbFormGroup,
		form_hubspot: FormHubspot,
		form_input: SbFormInput,
		form_row: SbFormRow,
		form_select: SbFormSelect,
		form_textarea: SbFormTextarea,
		form_with_heading: FormWithHeading,
		form: SbForm,
		hero: Hero,
		i_build_content: IBuildContent,
		i_build_image: IBuildImage,
		i_build_list: IBuildList,
		i_build_statistics: IBuildStatistics,
		i_build_tabs: IBuildTabs,
		i_build_testimonial: IBuildTestimonial,
		image_carousel: ImageCarousel,
		image_left_right: ImageLeftRight,
		insight: Insight,
		insights_filter: InsightsFilter,
		insights_overview: InsightsOverview,
		locations: Locations,
		logo_carousel: LogoCarousel,
		page: Page,
		pricing_panels: PricingPanels,
		self_subscribe: SelfSubscribePage,
		self_subscribe_carousel: SelfSubscribeCarousel,
		self_subscribe_image: SelfSubscribeImage,
		self_subscribe_image_graphic: SelfSubscribeImageGraphic,
		self_subscribe_video: SelfSubscribeVideo,
		testimonials: Testimonials,
		third_party_script: ThirdPartyScript,
		wysiwyg_blockquote: WysiwygBlockquote,
		wysiwyg_content: WysiwygContent,
		wysiwyg_image: WysiwygImage,
		wysiwyg_video: WysiwygVideo,
		wysiwyg_vimeo: WysiwygVimeo,
		wysiwyg_youtube: WysiwygYoutube,
		web_widget: WebWidget,
		workable: WorkableWidget,
		wysiwyg: WYSIWYG,
	},
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

export default function MyApp({ Component, pageProps }: AppProps) {
	const { events } = useRouter();

	useEffect(() => {
		TagManager.initialize({
			gtmId: process.env.NEXT_PUBLIC_GTM_ID
				? process.env.NEXT_PUBLIC_GTM_ID
				: '',
		});
		ReactGA.initialize('UA-151240425-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
		const handleRouteChange = (url: string) => {
			// pageview(url);
			ReactGA.pageview(window.location.pathname + window.location.search);
		};
		events.on('routeChangeComplete', handleRouteChange);
		return () => {
			events.off('routeChangeComplete', handleRouteChange);
		};
	}, [events]);

	useEffect(() => {
		import('react-facebook-pixel')
			.then((x) => x.default)
			.then((ReactPixel) => {
				ReactPixel.init(
					process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string,
				);
				ReactPixel.pageView();

				events.on('routeChangeComplete', () => {
					ReactPixel.pageView();
				});
			});
	}, [events]);

	return (
		<Fragment>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=0"
				/>
				<meta
					name="google-site-verification"
					content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION}
				/>
				<link rel="icon" href="/image.png" />
			</Head>

			<StoryblokClientProvider>
				<QueryClientProvider client={queryClient}>
					<HubspotProvider>
						<SettingsClientProvider>
							<HeaderClientProvider>
								<FooterClientProvider>
									<InsightsProvider>
										<Component {...pageProps} />
									</InsightsProvider>
								</FooterClientProvider>
							</HeaderClientProvider>
						</SettingsClientProvider>
					</HubspotProvider>
				</QueryClientProvider>
			</StoryblokClientProvider>
		</Fragment>
	);
}
