import { HeroContent } from '@/components/Bloks/Hero/HeroContent';
import { SbSection } from '@/components/Shared/Layout/SbSection';
import { SbImage } from '@/components/Shared/Image/SbImage';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IHeroBlok } from '@/utils/interfaces/bloks';
import React from 'react';

interface IProps {
	blok: IHeroBlok;
	preview: boolean;
}

const getEmbedUrl = (url: string): string | null => {
	if (!url) return null;

	if (url.includes('youtube.com') || url.includes('youtu.be')) {
		const videoId = url.split('v=')[1]?.split('&')[0] || url.split('/').pop();
		return `https://www.youtube.com/embed/${videoId}?autoplay=0&controls=1&modestbranding=1&rel=0`;
	} else if (url.includes('vimeo.com')) {
		const videoId = url.split('/').pop();
		return `https://player.vimeo.com/video/${videoId}?autoplay=0&controls=1&title=0&byline=0&portrait=0`;
	}
	return null;
};

export const HeroHome: React.FC<IProps> = ({ blok, preview }) => {
	const mediaSrc = blok.image?.filename || '';
	const altText = blok.image?.alt || '';

	const isUploadedVideo = mediaSrc.endsWith('.mp4');
	const isEmbed = mediaSrc.includes('youtube.com') || mediaSrc.includes('youtu.be') || mediaSrc.includes('vimeo.com');
	const embedUrl = isEmbed ? getEmbedUrl(mediaSrc) : null;

	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="hero-section relative lg:flex items-center bg-green-dark text-white bg-no-repeat bg-hero-home-mobile bg-right-top md:bg-hero-home md:bg-right-bottom md:bg-h-100 lg:min-h-[780px] px-4 lg:px-8"
		>
			{isStringWithValue(mediaSrc) && (
				<div className="inset-0 items-center pt-6 lg:absolute lg:flex lg:py-12">
					<div className="relative h-80 lg:h-full lg:w-1/2 lg:ml-auto px-4 lg:px-8">
						{isUploadedVideo ? (
							<video
								autoPlay
								loop
								muted
								playsInline
								controls
								className="w-full h-full object-contain"
								aria-label="Uploaded video"
							>
								<source src={mediaSrc} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						) : isEmbed && embedUrl ? (
							<div className="w-full h-full relative" style={{ aspectRatio: '16 / 9' }}>
								<iframe
									src={embedUrl}
									className="absolute top-0 left-0 w-full h-full"
									frameBorder="0"
									allow="autoplay; fullscreen; picture-in-picture"
									allowFullScreen
									title="Embedded Video"
									aria-label="Embedded video"
									style={{ zIndex: 1 }}
								/>
							</div>
						) : (
							<SbImage src={mediaSrc} layout="fill" objectFit="contain" alt={altText} />
						)}
					</div>
				</div>
			)}
			<HeroContent blok={blok} isHome />
		</SbSection>
	);
};